import SmoothScroll from 'smooth-scroll';
import AOS from 'aos';

const drawerAnchor = document.querySelectorAll('#navigation a');

drawer_open.addEventListener('click', e => {
    navigation.classList.add('open');
})

drawer_close.addEventListener('click', e => {
    navigation.classList.remove('open');
})

drawerAnchor.forEach(item => {
    item.addEventListener('click', e => {
        navigation.classList.remove('open');
    })
});

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    offset: function (anchor, navigation) {
        if(screen.width <= 640) {
            return 48;
        }else {
            return 72;
        }
    },
    easing: 'easeInOutQuint',
    updateURL: false,
    popstate: false
});

// アニメーション設定
AOS.init({
    delay: 0,
    offset: 100,
    once: true,
    duration: 800,
});




//下層ページのロゴサイズ変更
// const pageHeaders = document.querySelectorAll('.page .header');
// window.addEventListener('scroll', function () {
//     if (1 <= window.scrollY) {
//         pageHeaders.forEach(pageHeader => {
//             pageHeader.classList.add("inview");
//         });
//     } else {
//         pageHeaders.forEach(pageHeader => {
//             pageHeader.classList.remove("inview");
//         });
//     }
// });





const lang = document.querySelectorAll(".lang");
const inner_ul = document.querySelectorAll(".inner_ul");

lang.forEach(item =>{
    item.addEventListener("click", e =>{
        const target = e.currentTarget.querySelector(".inner_ul");
        if (target.classList.contains('show')){
            target.classList.remove("show");
        }else{
            target.classList.add("show");
        }
    })
})